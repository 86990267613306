import { keyframes } from '@chakra-ui/react';

export const animationKeyframesOpenAccordion = keyframes`
  0% { transform: rotate(90deg)}
  100% { transform: rotate(180deg)}
`;
export const animationKeyframesCloseAccordion = keyframes`
  0% { transform: rotate(0)}
  100% { transform: rotate(-90deg)}
`;

export const spin = keyframes`
  from { stroke-dasharray: 0 157; }
  to { stroke-dasharray: 157 157; }
`;

export const fadeUp = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;
